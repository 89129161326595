<template>
  <picture
    class="c-picture"
    :class="{ error: !isLoading && error, loading: isLoading }"
  >
    <img
      :alt
      class="c-picture__image"
      :src
      v-bind="attrs"
    />

    <BaseIcon2
      v-if="!isLoading && error"
      :id="fallbackIcon"
      class="c-picture__icon"
    />
  </picture>
</template>

<script lang="ts" setup>
import { useImage } from "@vueuse/core";

import type { ImgHTMLAttributes } from "vue";

interface Props extends /* @vue-ignore */ ImgHTMLAttributes {
  alt?: string;
  context?: "default" | "user";
  fallback?: string;
  src?: string;
}

const props = withDefaults(defineProps<Props>(), {
  alt: "",
  context: "default",
  fallback: "icon-image",
  src: "",
});

const { src } = toRefs(props);

const defaultIcons = {
  default: "icon-image",
  user: "icon-user",
};

const computedSource = computed(() => {
  return {
    src: src.value,
  };
});

const fallbackIcon = computed(() => {
  return defaultIcons[props.context];
});

const { error, isLoading } = useImage(computedSource);
const attrs = useAttrs();
</script>

<style scoped>
.c-picture {
  position: relative;
  display: flex;
  justify-content: center;
  line-height: 0;
  overflow: hidden;
  border: 1px solid transparent;
}

.c-picture.error {
  color: var(--grey-6);
  border-color: var(--grey-6);
}

.c-picture.loading::before,
.c-picture.loading::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.c-picture.loading::before {
  background-color: var(--grey-6);
}

.c-picture.loading::after {
  background-image: linear-gradient(to right, var(--grey-6) 0%, var(--white) 50%, var(--grey-6) 100%);
  animation-name: shimmer;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}

@keyframes shimmer {
  0% {
    translate: -100% 0;
  }

  100% {
    translate: 100% 0;
  }
}

.c-picture__image {
  flex-shrink: 0;
  object-fit: cover;
}

.c-picture.error .c-picture__image,
.c-picture.loading .c-picture__image {
  visibility: hidden;
  opacity: 0%;
}

.c-picture__icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
